<template>
  <div id="app">
    <Menu v-if="this.isLoggedIn"></Menu>
    <div class="pl-2 pr-2">
      <router-view/>
    </div>
    <notifications class="m-3"/>
    <b-modal :title="getHelper().trans('labels.2fa_required')"
             body-class="p-0 m-0"
             :modelValue="twoFaCodeModalConfig !== null"
             footer-class="d-flex justify-content-end"
             @update:modelValue="$event === false && (twoFaCodeModalConfig = null)">
      <template #default>
        <loaderWrapper ref="loaderTwoFaCode">
        <div class="d-flex justify-content-center flex-row p-3">
          <FormInputWrapper v-slot="parentProps" :errors="getHelper().getErrors('2fa', 'X-2FA-CODE')" >
            <input type="text"
                   v-maska
                   data-maska="999 999"
                   data-maska-tokens="9:[0-9]"
                   :class="parentProps.class" class="form-control" v-model="twoFaCode" :placeholder="getHelper().trans('labels.2fa_enter_code')">
          </FormInputWrapper>
          <div>
            <button type="button" @click.prevent="confirm2faCode()" class="btn btn-primary text-capitalize-first ms-2">
              {{ getHelper().trans('labels.2fa_verify') }}
            </button>
          </div>
        </div>
        </loaderWrapper>
      </template>
      <template #footer>
        <button type="button" @click.prevent="twoFaCodeModalConfig = null" class="btn btn-outline-secondary btn-sm text-capitalize-first mt-2">
          {{ getHelper().trans('button.cancel') }}
        </button>


      </template>
    </b-modal>

  </div>
</template>

<script>
import {mapGetters} from "vuex";
import axios from 'axios';
import Menu from './components/Menu';
import {BModal} from "bootstrap-vue-3";
import FormInputWrapper from "@/components/FormInputWrapper.vue";
import {helper} from "@/service/CommonHelper";
import LoaderWrapper from "@/components/LoaderWrapper.vue";

export default {
  name: "App",
  computed: {
    ...mapGetters([
      'isLoggedIn',
      'getAuth',
      'getUser',
    ]),
  },
  components: {
    LoaderWrapper,
    FormInputWrapper,
    BModal,
    Menu,
  },
  data() {
    return {
      promise2fa: new Promise((resolve, reject) => {}),
      twoFaCodeModalConfig: null,
      twoFaCode: '',
      resolve2fa: null
    }
  },
  methods: {
    getHelper: function () {
        return helper
    },
    confirm2faCode: async function () {
      try {
        this.$refs.loaderTwoFaCode?.show();
        this.twoFaCodeModalConfig.headers['x-2fa-code'] = this.twoFaCode.replace(" ", "")
        const resp = await axios.request(this.twoFaCodeModalConfig);
        this.resolve2fa(resp)
        this.twoFaCodeModalConfig = null
      } catch (e) {
        helper.handleErrors('2fa', e)
      } finally {
        this.$refs.loaderTwoFaCode?.hide();
      }
    },
    setupAxiosDefaults: function () {
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.getAuth.token
    },
    setupAxios: function () {
      let self = this;
      let auth = {
        isRefreshing: false,
        refreshingCall: undefined,
      }

      function refreshToken(cb) {
        if (auth.isRefreshing) {
          return auth.refreshingCall.then(cb);
        }

        auth.isRefreshing = true;

        const refreshToken = self.getAuth.refreshToken;
        auth.refreshingCall = axios.post('/token/refresh', {refreshToken: refreshToken}, {headers: {Authorization: ""}})
          .then(tokenRefreshResponse => {
            self.$store.commit('authenticate', tokenRefreshResponse.data);
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + tokenRefreshResponse.data.token
            auth.isRefreshing = false;
            auth.refreshingCall = undefined;
            return Promise.resolve(tokenRefreshResponse.data.token);
          }).then(cb);
        return auth.refreshingCall;
      }

      axios.interceptors.response.use(
        (response) => response,
        async (error) => {
          const request = error.config;
          if (
            request &&
            request.url === '/token/refresh' &&
            request.method === 'post' &&
            error &&
            error.response &&
            error.response.status === 401
          ) {
            self.$store.commit('removeAuth');
            window.location.reload();
            return;
          }

          if (error && error.response && error.response.status === 401) {
            if (error.response.headers['x-2fa-code'] === 'required') {
              this.twoFaCodeModalConfig = error.config;
              this.twoFaCode = '';
              helper.clearErrors('2fa')
              return new Promise((resolve, reject) => {
                this.resolve2fa = resolve
              });
            }

            return refreshToken(() => {
              error.config.headers['Authorization'] = 'Bearer ' + this.getAuth.token
              return axios.request(error.config);
            });
          }

          throw error;
        }
      );


    }
  },
  async created() {
    this.setupAxios();
    if (this.isLoggedIn) {
      this.setupAxiosDefaults();
      await this.$store.dispatch('loadUser');
    }
  },
  watch: {
    async isLoggedIn(newValue, oldValue) {
      if (newValue) {
        this.setupAxiosDefaults();
        await this.$store.dispatch('loadUser');
      }
    }
  }
}
</script>

