import {Module} from "vuex";
import CurrencyMethodsDTO from "@/models/CurrencyMethodsDTO";
import APIClient from "@/service/APIClient";
import PaymentOptionsDTO from "@/models/PaymentOptionsDTO";

const api = new APIClient();

const currencyMethods: Module<any, any> = {
    namespaced: true,
    state: {
        methods: [] as CurrencyMethodsDTO[],
        options: [] as PaymentOptionsDTO[],
    },
    actions: {
        async loadList ({ state, commit }) {
            const methods = await api.paymentMethods();
            const options = await api.paymentOptions();

            commit('SET_LIST', {
                methods,
                options
            });
        },
    },
    mutations: {
        SET_LIST: (state, payload: any) => {
            state.methods = payload.methods;
            state.options = payload.options;
        },
    },
    getters: {
        currencies: (state): string[] => state.methods.map((value: CurrencyMethodsDTO) => value.currency),
        methods: (state) => (currency: string|null = null): string[] => {
            if (!currency) {
                let methods = [];
                for (const item of state.methods) {
                    for (const method of item.methods) {
                        if (methods.indexOf(method) === -1) {
                            methods.push(method);
                        }
                    }
                }

                return methods;
            }

            let currencyMethods = state.methods.find((item: CurrencyMethodsDTO) => item.currency === currency);
            if (!currencyMethods) {
                return []
            }

            return currencyMethods.methods
        },
        options: (state) => (currency: string|null = null): string[] => {
            if (!currency) {
                let options = [];
                for (const item of state.options) {
                    for (const option of item.options) {
                        if (options.indexOf(option) === -1) {
                            options.push(option);
                        }
                    }
                }

                return options;
            }

            let currencyMethods = state.options.find((item: PaymentOptionsDTO) => item.currency === currency);
            if (!currencyMethods) {
                return []
            }

            return currencyMethods.options
        },
        list: (state): CurrencyMethodsDTO[] => state.methods,
    }
}

export default currencyMethods;
