import {createStore} from 'vuex'
import createPersistedState from "vuex-persistedstate";
import AccessTokenDTO from "@/models/AccessTokenDTO";
import TraderDTO from "@/models/TraderDTO";
import router from './../router'
import APIClient from "@/service/APIClient";
import currencyMethods from "@/store/currencyMethods";
import {UserRole} from "@/models/enum/UserRole";
import MerchantDTO from "@/models/MerchantDTO";
import UserDTO from "@/models/UserDTO";
import devices from "@/store/devices";
import merchantStores from "@/store/merchantStores";
import reqGroups from "@/store/reqGroups";

function loadTokenFromLocalStorage(): AccessTokenDTO | null
{
  try {
    const token = localStorage.getItem('auth') || '';
    return JSON.parse(token) as AccessTokenDTO;
  } catch (e) {
    return null
  }
}

const auth = loadTokenFromLocalStorage();

const api = new APIClient()
export default createStore({
  state: {
    auth: auth as AccessTokenDTO | null,
    user: null as TraderDTO | MerchantDTO | UserDTO | null,
  },
  mutations: {
    authenticate(state, token: AccessTokenDTO) {
      state.auth = token;
      localStorage.setItem('auth', JSON.stringify({token: token.token, refreshToken: token.refreshToken}));
    },
    removeAuth(state) {
      localStorage.removeItem('auth');
      state.auth = null;
      state.user = null;
      router.push({ name: 'login'})
    },
    setUser(state, user: TraderDTO | null) {
      state.user = user;
    },
    setUserStatus(state, isActive: boolean) {
      (state.user as TraderDTO)!.isActive = isActive
    }
  },
  actions: {
    async loadUser({commit}) {
      return api.me().then((user) => {
        commit('setUser', user);
      }).catch(() => {

      });
    },
    logout({commit}) {
      commit('removeAuth');
    },
    toggleTraderStatus({commit}, isActive: boolean) {
      return api.changeTraderStatus(isActive).then(() => {
        commit('setUserStatus', isActive);
      }).catch(() => {

      });
    },
    login({commit}, credentials) {

      return api.tokenGet(credentials).then((accessToken) => {
        commit('authenticate', accessToken);
        commit('merchantStores/SET_CURRENT_STORE', null);
      }).catch((error) => {
        throw error;
      });
    },
    useAccessToken({commit}, accessToken: AccessTokenDTO) {
      commit('authenticate', accessToken);
      commit('merchantStores/SET_CURRENT_STORE', null);
    }
  },
  getters: {
    isLoggedIn: state => state.auth !== null,
    getAuth: state => state.auth,
    getUser: state => state.user,
    getRole: state => state.user?.role,
    hasRole: state => (roles: string[]): boolean => {
      if (!state.user?.role) {
        return false;
      }
      return roles.includes(state.user!.role)
    },
    isTrader: state => state.user && state.user.role === UserRole.TRADER,
    isOperator: state => state.user && state.user.role === UserRole.OPERATOR,
    isAdmin: state => state.user && state.user.role === UserRole.ADMIN,
    isMerchant: state => state.user && state.user.role === UserRole.MERCHANT,
  },
  strict: true,
  plugins: [createPersistedState()],
  modules: {
    currencyMethods,
    devices,
    merchantStores,
    reqGroups
  }
})
